@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Dosis:200,300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900,900i");
@import "~angular-calendar/css/angular-calendar.css";
@import url("https://s3-us-west-1.amazonaws.com/ogr-icons-font/style.ogr_fonts.css");
body.body-default {
  background-color: #f1f1f2;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

/* Profile 1: docentes */
body.profile1,
.body_profile1 {
  background-color: #f1f1f2;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

/* Profile 2: estudiantes */
body.profile2 {
  background-color: #f1f1f2;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

body.profile3 {
  background-color: #f1f1f2;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

.btn_base_1 {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #2691c5;
  color: white;
  border: none;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}
.btn_base_1:hover {
  background: #1e729a;
}
.btn_base_1:hover:active {
  color: white;
}
.btn_base_1:focus {
  color: white;
}

.btn_base_2 {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #bfbfbf;
  border: none;
}
.btn_base_2:hover {
  background: #d0d0d0;
}

.btn_base_3 {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #bfef8c;
  color: #526f32;
  border: none;
}
.btn_base_3:hover {
  background: #b6e682;
}

.btn-table {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #2691c5;
  color: white;
  border: none;
  padding: 6px;
  width: 26px;
  height: 26px;
  margin: 2px;
  line-height: 14px;
  font-size: 14px;
}
.btn-table:hover {
  background: #1e729a;
}
.btn-table:focus {
  color: white;
}

.small-round-btn {
  background-color: #336699;
  border-radius: 50%;
  display: block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.small-round-btn:hover {
  opacity: 0.8;
}
.small-round-btn .small-round-btn-inner {
  display: table-row;
}
.small-round-btn a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.custom_title_1,
.custom_title {
  padding: 12px 20px 18px 20px;
  color: #4e5457;
  background: url(/public/img/h2.png) no-repeat;
  background-size: 100% 100%;
  max-width: 481px;
  height: 86px;
  text-align: center;
  margin: 30px auto 20px auto;
}
.custom_title_1 h2,
.custom_title h2 {
  margin: 0;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  font-family: var(--custom-font-family1);
  font-size: 32px;
}

.custom_title_2 {
  position: relative;
  z-index: 1;
  text-align: center;
}
.custom_title_2 .ribbon {
  font-weight: normal;
  font-size: 26px;
  display: inline-block;
  max-width: 90%;
  min-width: 30%;
  font-family: var(--custom-font-family1), Sans-Serif;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  position: relative;
  background: #ccb194;
  color: #fff;
  text-align: center;
  padding: 0.5em 1em; /* Adjust to suit */
  margin: 1em auto 1em;
}
.custom_title_2 .ribbon:before,
.custom_title_2 .ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -0.5em;
  border: 1em solid #b59069;
  z-index: -1;
}
.custom_title_2 .ribbon:before {
  left: -2em;
  border-right-width: 2em;
  border-left-color: transparent;
}
.custom_title_2 .ribbon:after {
  right: -2em;
  border-left-width: 2em;
  border-right-color: transparent;
}
.custom_title_2 .ribbon .ribbon-content:before,
.custom_title_2 .ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #96714b transparent transparent transparent;
  bottom: -0.5em;
}
.custom_title_2 .ribbon .ribbon-content:before {
  left: 0;
  border-width: 0.5em 0 0 1em;
}
.custom_title_2 .ribbon .ribbon-content:after {
  right: 0;
  border-width: 0.5em 1em 0 0;
}

.h3_sub_title_1 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 10px;
  border-bottom: 3px solid #2691c5;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_2 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 10px;
  border-bottom: 3px solid #2691c5;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_3 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 10px;
  border-bottom: 3px solid #2691c5;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_9c3 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 10px;
  border-bottom: 3px solid #9c3;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h4_sub_title_1 {
  font-weight: 700;
  font-size: 14px;
}

table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas tbody > tr > th,
table.tabla_estadisticas thead > tr > th {
  padding: 4px 6px;
  vertical-align: middle;
}
table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas tbody > tr > th {
  border: none;
}
table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas thead > tr > th {
  text-align: center;
}
table.tabla_estadisticas tbody > tr:nth-of-type(odd) {
  background-color: #e9f5d2;
}
table.tabla_estadisticas thead > tr > th {
  border-bottom: 2px solid #c0d696;
}
table.tabla_estadisticas thead > tr > th:first-child,
table.tabla_estadisticas tbody > tr > th:first-child {
  text-align: left;
}

table.tabla_ensayos thead > tr > th,
table.tabla_planes_personales thead > tr > th {
  padding: 8px 6px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  border: 2px solid white;
  border-top: none;
  border-bottom: none;
  background-color: #fff4d2;
  color: #663300;
}
table.tabla_ensayos thead > tr > th:first-child,
table.tabla_planes_personales thead > tr > th:first-child {
  border-left: none;
  border-radius: 6px 0 0 0;
}
table.tabla_ensayos thead > tr > th:last-child,
table.tabla_planes_personales thead > tr > th:last-child {
  border-right: none;
  border-radius: 0 6px 0 0;
}
table.tabla_ensayos thead > tr > th.actions,
table.tabla_planes_personales thead > tr > th.actions {
  width: 1%;
}
table.tabla_ensayos tbody > tr > td,
table.tabla_planes_personales tbody > tr > td {
  padding: 5px;
  font-size: 12px;
  text-align: left;
  vertical-align: middle;
  border: none;
}
table.tabla_ensayos tbody > tr > td.actions,
table.tabla_planes_personales tbody > tr > td.actions {
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
table.tabla_ensayos tbody > tr:nth-of-type(even),
table.tabla_planes_personales tbody > tr:nth-of-type(even) {
  background-color: #f1f1f1;
}
table.tabla_ensayos tbody > tr > td.min-size,
table.tabla_ensayos thead > tr > th.min-size,
table.tabla_planes_personales tbody > tr > td.min-size,
table.tabla_planes_personales thead > tr > th.min-size {
  width: 1%;
  text-align: center;
}
table.tabla_ensayos tbody > tr > th.acciones,
table.tabla_ensayos tbody > tr > td.acciones,
table.tabla_planes_personales tbody > tr > th.acciones,
table.tabla_planes_personales tbody > tr > td.acciones {
  width: 1%;
  text-align: center;
}
table.tabla_ensayos tbody > tr > td.correcta,
table.tabla_planes_personales tbody > tr > td.correcta {
  background-color: #d5eca9;
}
table.tabla_ensayos.color-scheme-1 thead > tr > th,
table.tabla_planes_personales.color-scheme-1 thead > tr > th {
  background-color: #fff4d2;
  color: #663300;
}
table.tabla_ensayos.color-scheme-2 thead > tr > th,
table.tabla_planes_personales.color-scheme-2 thead > tr > th {
  background-color: #d6d6d6;
  color: #5f5f5f;
}

.carousel_style_1 {
  padding-bottom: 30px;
}
.carousel_style_1 .sp-controllers {
  bottom: 0px;
}
@media screen and (min-width: 700px) {
  .carousel_style_1 .sp-arrow-left {
    left: 50%;
    margin-left: -260px;
  }
  .carousel_style_1 .sp-arrow-right {
    right: 50%;
    margin-right: -260px;
  }
}
.carousel_style_1 .circle {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: none;
  background-color: #ffcc33;
  color: #663300;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.carousel_style_1 .circle:hover {
  background-color: #ffe38e;
}
.carousel_style_1 .circle span.circle-number {
  font-size: 16px;
  display: block;
}
.carousel_style_1 .selected-circle {
  background-color: #ffe38e;
}
.carousel_style_1 .sp-slider > li h4 {
  display: inline-block;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 24px;
  margin-top: 10px;
  padding: 3px 5px;
  margin-bottom: 10px;
  color: #333;
  max-width: 440px;
  background: rgba(255, 255, 255, 0.4);
}
@media screen and (max-width: 500px) {
  .carousel_style_1 .sp-slider > li h4 {
    font-size: 18px;
  }
}
.carousel_style_1 .sp-slider > li img {
  padding: 10px 0 0 0;
}

.cui-showcasebox-theme-default .showcasebox .showcasebox_title {
  padding: 6px 0px;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: table-row;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon {
  display: table-cell;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: #2691c5;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 1;
  display: block;
  color: white;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  display: table-cell;
  vertical-align: middle;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 22px;
  color: #5b5b5f;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_content {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_content .info_text {
  font-size: 90%;
  color: #949494;
  margin-bottom: 0;
}

.showcasebox_style_1 .showcasebox .showcasebox_title {
  padding: 6px 0px;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: table-row;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon {
  display: table-cell;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: #2691c5;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 1;
  display: block;
  color: white;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  display: table-cell;
  vertical-align: middle;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 22px;
  color: #5b5b5f;
}
.showcasebox_style_1 .showcasebox .showcasebox_content {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}
.showcasebox_style_1 .showcasebox .showcasebox_content .info_text {
  font-size: 90%;
  color: #949494;
  margin-bottom: 0;
}

.showcasebox_style_1_carreras .showcasebox .showcasebox_title {
  padding: 6px 0px;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: table-row;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon {
  display: table-cell;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-carreras, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 1;
  display: block;
  color: white;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  display: table-cell;
  vertical-align: middle;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 22px;
  color: #5b5b5f;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_content {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_content .info_text {
  font-size: 90%;
  color: #949494;
  margin-bottom: 0;
}

.showcasebox_style_1_primary .showcasebox .showcasebox_title {
  padding: 6px 0px;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: table-row;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon {
  display: table-cell;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-primary, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 1;
  display: block;
  color: white;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  display: table-cell;
  vertical-align: middle;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 22px;
  color: #5b5b5f;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_content {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_content .info_text {
  font-size: 90%;
  color: #949494;
  margin-bottom: 0;
}

.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title {
  padding: 6px 0px;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: table-row;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon {
  display: table-cell;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-establecimientos, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 1;
  display: block;
  color: white;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  display: table-cell;
  vertical-align: middle;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 22px;
  color: #5b5b5f;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_content {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_content .info_text {
  font-size: 90%;
  color: #949494;
  margin-bottom: 0;
}

.showcasebox_style_1_blog .showcasebox .showcasebox_title {
  padding: 6px 0px;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: table-row;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon {
  display: table-cell;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-blog, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 1;
  display: block;
  color: white;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  display: table-cell;
  vertical-align: middle;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 22px;
  color: #5b5b5f;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_content {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_content .info_text {
  font-size: 90%;
  color: #949494;
  margin-bottom: 0;
}

.showcasebox_style_1_ranking .showcasebox .showcasebox_title {
  padding: 6px 0px;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: table-row;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon {
  display: table-cell;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: #e6b74c;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 1;
  display: block;
  color: white;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  display: table-cell;
  vertical-align: middle;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 22px;
  color: #5b5b5f;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_content {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_content .info_text {
  font-size: 90%;
  color: #949494;
  margin-bottom: 0;
}

.showcasebox_style_user .grid-layout {
  display: flex;
  align-items: center;
}
.showcasebox_style_user .btn_style {
  font-size: 12px;
}
.showcasebox_style_user .left_column {
  padding-right: 5px;
}
.showcasebox_style_user .right_column {
  padding-left: 5px;
}
.showcasebox_style_user .user-circle-container {
  background-color: #5fb9e6;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 0;
  min-height: auto;
  height: 80px;
  width: 80px;
}
.showcasebox_style_user .user-circle-container .fa-placeholder .fa {
  font-size: 80px;
  padding-top: 6px;
}
.showcasebox_style_user .user-circle-container img.avatar {
  width: 100%;
  height: 100%;
}
.showcasebox_style_user h4 {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 20px;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 10px;
  color: #2691c5;
}
.showcasebox_style_user h4:hover {
  text-decoration: underline;
}
.showcasebox_style_user .email {
  margin-bottom: 15px;
  color: #5b5b5f;
}
.showcasebox_style_user .email fa {
  color: #5b5b5f;
  font-size: 110%;
  opacity: 0.4;
}
.showcasebox_style_user .profile-link {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 13px;
}
.showcasebox_style_user .profile-link:hover {
  text-decoration: none;
}
.showcasebox_style_user .edit-link {
  display: inline-block;
  font-size: 13px;
}
.showcasebox_style_user .edit-link:hover {
  text-decoration: none;
}
.showcasebox_style_user .no-email {
  margin-bottom: 15px;
  color: #848484;
  font-size: 90%;
}
.showcasebox_style_user .no-email fa {
  color: #f7cb43;
  font-size: 110%;
}
.showcasebox_style_user .clear-btn {
  margin-bottom: 5px;
}

body .modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}
body .modal-content .modal-header {
  border-bottom: none;
}
body .modal-content .modal-header .close {
  font-size: 34px;
  margin-top: -4px;
}
body .modal-content .modal-header .modal-title {
  color: #5b5b5f;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.menu_item_box {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
  padding: 10px;
  display: block;
  color: #333;
}
@media screen and (max-width: 500px) {
  .menu_item_box {
    margin-bottom: 20px;
  }
}
.menu_item_box:hover {
  text-decoration: none;
}
.menu_item_box:focus {
  outline: none;
  text-decoration: none;
}
.menu_item_box:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.menu_item_box .menu_item_box_wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.menu_item_box .menu_item_row_wrapper {
  display: table-row;
}
.menu_item_box .menu_item_box_icon_container {
  display: table-cell;
  width: 100px;
  vertical-align: top;
  padding-right: 10px;
}
@media screen and (max-width: 500px) {
  .menu_item_box .menu_item_box_icon_container {
    width: 70px;
  }
}
.menu_item_box .menu_item_box_icon_container .menu_item_box_icon {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #f1f1f1;
  overflow: hidden;
  width: 90px;
  height: 90px;
  text-align: center;
}
.menu_item_box .menu_item_box_icon_container .menu_item_box_icon .icono {
  font-size: 62px;
  color: silver;
}
@media screen and (max-width: 500px) {
  .menu_item_box .menu_item_box_icon_container .menu_item_box_icon {
    width: 60px;
    height: 60px;
  }
  .menu_item_box .menu_item_box_icon_container .menu_item_box_icon .icono {
    font-size: 44px;
  }
}
.menu_item_box .menu_item_box_body {
  display: table-cell;
  vertical-align: top;
  padding-bottom: 40px;
  position: relative;
}
.menu_item_box .menu_item_box_body .menu_item_box_title {
  margin: 0 0 10px;
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 10px;
  border-bottom: 3px solid #9c3;
  font-family: var(--custom-font-family1), Sans-Serif;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: auto;
}
.menu_item_box .menu_item_box_body .menu_item_box_text {
  color: #898989;
}
.menu_item_box .menu_item_box_link {
  position: absolute;
  bottom: 10px;
  text-align: right;
  right: 10px;
  line-height: 26px;
}
.menu_item_box .menu_item_box_link .arrow {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
  color: #9c3;
}
.menu_item_box.locked {
  cursor: default;
}
.menu_item_box.locked .menu_item_box_wrapper,
.menu_item_box.locked .menu_item_box_link {
  filter: blur(2px);
}
.menu_item_box.locked .locked-menu-item {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  background-color: rgba(39, 145, 197, 0.7);
  color: white;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
}
.menu_item_box.locked .locked-menu-item .padlock {
  font-size: 30px;
  float: left;
  margin-right: 10px;
}
.menu_item_box.locked .locked-menu-item .locked-info {
  margin: 0;
  margin-left: 30px;
  display: block;
}

nav.navbar ul.nav > li > a {
  color: #336699;
}
nav.navbar ul.nav > li > a:hover {
  background-color: rgba(51, 102, 153, 0.1);
  color: #336699;
}

:root {
  --background-color-profile1: #f1f1f2;
  --background-color-navbar-profile1: #ffffff;
  --font-color-navbar-profile1: #336699;
  --font-color-hover-navbar-profile1: #336699;
  --responsive-toggle-button-background-color: #fdb55e;
  --responsive-toggle-button-font-color: black;
  --color-omitidas: #c0c0c0;
  --color-correctas: #9ec74d;
  --color-incorrectas: #ea6767;
  --palette-colors-primary: #2691c5;
  --palette-colors-primary-h: 199.6226415094deg;
  --palette-colors-primary-s: 67.6595744681%;
  --palette-colors-primary-l: 46.0784313725%;
  --palette-colors-primary-r: 38;
  --palette-colors-primary-g: 145;
  --palette-colors-primary-b: 197;
  --palette-colors-primario: #2691c5;
  --palette-colors-primario-h: 199.6226415094deg;
  --palette-colors-primario-s: 67.6595744681%;
  --palette-colors-primario-l: 46.0784313725%;
  --palette-colors-primario-r: 38;
  --palette-colors-primario-g: 145;
  --palette-colors-primario-b: 197;
  --palette-colors-background: #fafafa;
  --palette-colors-background-h: 0deg;
  --palette-colors-background-s: 0%;
  --palette-colors-background-l: 98.0392156863%;
  --palette-colors-background-r: 250;
  --palette-colors-background-g: 250;
  --palette-colors-background-b: 250;
  --palette-colors-fontColor: #5b5b5f;
  --palette-colors-fontColor-h: 240deg;
  --palette-colors-fontColor-s: 2.1505376344%;
  --palette-colors-fontColor-l: 36.4705882353%;
  --palette-colors-fontColor-r: 91;
  --palette-colors-fontColor-g: 91;
  --palette-colors-fontColor-b: 95;
  --palette-colors-noticias: #7faf5b;
  --palette-colors-noticias-h: 94.2857142857deg;
  --palette-colors-noticias-s: 34.4262295082%;
  --palette-colors-noticias-l: 52.1568627451%;
  --palette-colors-noticias-r: 127;
  --palette-colors-noticias-g: 175;
  --palette-colors-noticias-b: 91;
  --palette-colors-facebook: #3c5a99;
  --palette-colors-facebook-h: 220.6451612903deg;
  --palette-colors-facebook-s: 43.661971831%;
  --palette-colors-facebook-l: 41.7647058824%;
  --palette-colors-facebook-r: 60;
  --palette-colors-facebook-g: 90;
  --palette-colors-facebook-b: 153;
  --palette-colors-instagram: #c13584;
  --palette-colors-instagram-h: 326.1428571429deg;
  --palette-colors-instagram-s: 56.9105691057%;
  --palette-colors-instagram-l: 48.2352941176%;
  --palette-colors-instagram-r: 193;
  --palette-colors-instagram-g: 53;
  --palette-colors-instagram-b: 132;
  --palette-colors-twitter: #38a1f3;
  --palette-colors-twitter-h: 206.3101604278deg;
  --palette-colors-twitter-s: 88.6255924171%;
  --palette-colors-twitter-l: 58.6274509804%;
  --palette-colors-twitter-r: 56;
  --palette-colors-twitter-g: 161;
  --palette-colors-twitter-b: 243;
  --palette-colors-linkedin: #0077b5;
  --palette-colors-linkedin-h: 200.5524861878deg;
  --palette-colors-linkedin-s: 100%;
  --palette-colors-linkedin-l: 35.4901960784%;
  --palette-colors-linkedin-r: 0;
  --palette-colors-linkedin-g: 119;
  --palette-colors-linkedin-b: 181;
  --palette-colors-youtube: #ff0000;
  --palette-colors-youtube-h: 0deg;
  --palette-colors-youtube-s: 100%;
  --palette-colors-youtube-l: 50%;
  --palette-colors-youtube-r: 255;
  --palette-colors-youtube-g: 0;
  --palette-colors-youtube-b: 0;
  --palette-colors-twitch: #6441a5;
  --palette-colors-twitch-h: 261deg;
  --palette-colors-twitch-s: 43.4782608696%;
  --palette-colors-twitch-l: 45.0980392157%;
  --palette-colors-twitch-r: 100;
  --palette-colors-twitch-g: 65;
  --palette-colors-twitch-b: 165;
  --palette-colors-google: #4285f4;
  --palette-colors-google-h: 217.4157303371deg;
  --palette-colors-google-s: 89%;
  --palette-colors-google-l: 60.7843137255%;
  --palette-colors-google-r: 66;
  --palette-colors-google-g: 133;
  --palette-colors-google-b: 244;
  --palette-colors-tiktok: #0defe9;
  --palette-colors-tiktok-h: 178.407079646deg;
  --palette-colors-tiktok-s: 89.6825396825%;
  --palette-colors-tiktok-l: 49.4117647059%;
  --palette-colors-tiktok-r: 13;
  --palette-colors-tiktok-g: 239;
  --palette-colors-tiktok-b: 233;
  --palette-colors-establecimientos: #309fb2;
  --palette-colors-establecimientos-h: 188.7692307692deg;
  --palette-colors-establecimientos-s: 57.5221238938%;
  --palette-colors-establecimientos-l: 44.3137254902%;
  --palette-colors-establecimientos-r: 48;
  --palette-colors-establecimientos-g: 159;
  --palette-colors-establecimientos-b: 178;
  --palette-colors-carreras-like: #d3267f;
  --palette-colors-carreras-like-h: 329.1329479769deg;
  --palette-colors-carreras-like-s: 69.4779116466%;
  --palette-colors-carreras-like-l: 48.8235294118%;
  --palette-colors-carreras-like-r: 211;
  --palette-colors-carreras-like-g: 38;
  --palette-colors-carreras-like-b: 127;
  --palette-colors-carreras-like-universidad: #22daa5;
  --palette-colors-carreras-like-universidad-h: 162.7173913043deg;
  --palette-colors-carreras-like-universidad-s: 73.0158730159%;
  --palette-colors-carreras-like-universidad-l: 49.4117647059%;
  --palette-colors-carreras-like-universidad-r: 34;
  --palette-colors-carreras-like-universidad-g: 218;
  --palette-colors-carreras-like-universidad-b: 165;
  --palette-colors-blog: #e6b74c;
  --palette-colors-blog-h: 41.6883116883deg;
  --palette-colors-blog-s: 75.4901960784%;
  --palette-colors-blog-l: 60%;
  --palette-colors-blog-r: 230;
  --palette-colors-blog-g: 183;
  --palette-colors-blog-b: 76;
  --palette-colors-logros: #e6b74c;
  --palette-colors-logros-h: 41.6883116883deg;
  --palette-colors-logros-s: 75.4901960784%;
  --palette-colors-logros-l: 60%;
  --palette-colors-logros-r: 230;
  --palette-colors-logros-g: 183;
  --palette-colors-logros-b: 76;
  --footer-push-height: 300px;
  --custom-font-family3: Sans serif;
  --custom-font-family4: Sans serif;
  --base-landing_logo_max_width: 400px;
  --base-landing_logo_footer_height: 40px;
  --base-landing_login_align: center;
  --background-color-profile1-h: 240deg;
  --background-color-profile1-s: 3.7037037037%;
  --background-color-profile1-l: 94.7058823529%;
  --background-color-profile1-r: 241;
  --background-color-profile1-g: 241;
  --background-color-profile1-b: 242;
  --background-color-navbar-profile1-h: 0deg;
  --background-color-navbar-profile1-s: 0%;
  --background-color-navbar-profile1-l: 100%;
  --background-color-navbar-profile1-r: 255;
  --background-color-navbar-profile1-g: 255;
  --background-color-navbar-profile1-b: 255;
  --font-color-navbar-profile1-h: 210deg;
  --font-color-navbar-profile1-s: 50%;
  --font-color-navbar-profile1-l: 40%;
  --font-color-navbar-profile1-r: 51;
  --font-color-navbar-profile1-g: 102;
  --font-color-navbar-profile1-b: 153;
  --font-color-hover-navbar-profile1-h: 210deg;
  --font-color-hover-navbar-profile1-s: 50%;
  --font-color-hover-navbar-profile1-l: 40%;
  --font-color-hover-navbar-profile1-r: 51;
  --font-color-hover-navbar-profile1-g: 102;
  --font-color-hover-navbar-profile1-b: 153;
  --responsive-toggle-button-background-color-h: 32.8301886792deg;
  --responsive-toggle-button-background-color-s: 97.5460122699%;
  --responsive-toggle-button-background-color-l: 68.0392156863%;
  --responsive-toggle-button-background-color-r: 253;
  --responsive-toggle-button-background-color-g: 181;
  --responsive-toggle-button-background-color-b: 94;
  --responsive-toggle-button-font-color-h: 0deg;
  --responsive-toggle-button-font-color-s: 0%;
  --responsive-toggle-button-font-color-l: 0%;
  --responsive-toggle-button-font-color-r: 0;
  --responsive-toggle-button-font-color-g: 0;
  --responsive-toggle-button-font-color-b: 0;
  --color-omitidas-h: 0deg;
  --color-omitidas-s: 0%;
  --color-omitidas-l: 75.2941176471%;
  --color-omitidas-r: 192;
  --color-omitidas-g: 192;
  --color-omitidas-b: 192;
  --color-correctas-h: 80.1639344262deg;
  --color-correctas-s: 52.1367521368%;
  --color-correctas-l: 54.1176470588%;
  --color-correctas-r: 158;
  --color-correctas-g: 199;
  --color-correctas-b: 77;
  --color-incorrectas-h: 0deg;
  --color-incorrectas-s: 75.7225433526%;
  --color-incorrectas-l: 66.0784313725%;
  --color-incorrectas-r: 234;
  --color-incorrectas-g: 103;
  --color-incorrectas-b: 103;
}

/* ############################################## 

Código loader Spinner Puntaje fuera de la aplicación

############################################## */
.outside-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
  height: 60px;
}

.spinner {
  margin: 0 auto;
  font-size: 8px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(169, 169, 169, 0.2);
  border-right: 1.1em solid rgba(169, 169, 169, 0.2);
  border-bottom: 1.1em solid rgba(169, 169, 169, 0.2);
  border-left: 1.1em solid #3399ff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spin 1.1s infinite linear, color-puntaje-outside 3s infinite linear;
  animation: spin 1.1s infinite linear, color-puntaje-outside 3s infinite linear;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes color-puntaje-outside {
  0% {
    border-left: 1.1em solid #3399ff;
  }
  20% {
    border-left: 1.1em solid #9c3;
  }
  40% {
    border-left: 1.1em solid #fc0;
  }
  60% {
    border-left: 1.1em solid #f90;
  }
  80% {
    border-left: 1.1em solid #f33;
  }
  100% {
    border-left: 1.1em solid #3399ff;
  }
}
@keyframes color-puntaje-outside {
  0% {
    border-left: 1.1em solid #3399ff;
  }
  20% {
    border-left: 1.1em solid #9c3;
  }
  40% {
    border-left: 1.1em solid #fc0;
  }
  60% {
    border-left: 1.1em solid #f90;
  }
  80% {
    border-left: 1.1em solid #f33;
  }
  100% {
    border-left: 1.1em solid #3399ff;
  }
}
html {
  display: block;
  height: 100%;
  font-size: 16px;
}

puntaje {
  display: block;
  height: 100%;
}

body {
  display: block;
  height: 100%;
}

@-webkit-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@media print {
  .print-hide {
    display: none !important;
  }
  .print-show-block {
    display: block !important;
  }
  .print-page-break-after {
    page-break-after: always;
    display: block;
  }
  .print-page-break-before {
    page-break-before: always;
    display: block;
  }
  .print-page-break-avoid {
    page-break-inside: avoid;
  }
  .print-full-width {
    width: 100% !important;
  }
  .print-force-expand {
    height: auto !important;
    overflow: auto !important;
  }
}
@page {
  size: auto;
  margin: 0.5in 0.5in 0.5in 0.5in;
}
.index-hidden {
  display: none;
}

.using-mouse button:focus,
.using-mouse button:active:focus {
  outline: none;
}